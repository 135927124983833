export interface PartnerDetails {
    partnerId: string;
    partnerName: string;
    partnerWebsite: string;
    partnerLogoUrl: string;

    style?: {
        footer?: {
            backgroundColor?: string;
            color?: string;
        };
    };
}

/**
 * Get partner details based on the partner ID, returns undefined if the partner ID is not recognized.
 *
 * @param partnerId
 * @returns
 */
export function getPartnerDetails(partnerId?: string): PartnerDetails | undefined {
    switch (partnerId) {
        case "sfl":
        case "surefire-local":
            return {
                partnerId: "sfl",
                partnerName: "Surefire Local",
                partnerWebsite: "https://www.surefirelocal.com/",
                partnerLogoUrl: "/partner/surefire-local.svg",
                // Custom styling for footer, to better match the partner's branding, specifically the partner logo
                style: {
                    footer: {
                        backgroundColor: "#fdfdfd",
                        color: "#000000",
                    },
                },
            };
        default:
            return undefined;
    }
}
