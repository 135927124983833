import React, { FC, useCallback, useEffect } from "react";

import { FormDateInput, BusyDayDescription } from "stentor-models";

import dayjs, { Dayjs } from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormHelperText from "@mui/material/FormHelperText";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";

import { log } from "../../utils";
import { FormFieldProps } from "../SearchResponseFormDisplay";

import styles from "./FormFieldDate.module.scss";

export const DEFAULT_BUSY_DAYS: BusyDayDescription = {
    blockWeekends: true,
    blockCurrentDay: true,
    blockNextBusinessDays: 2,
};

export interface FormDateProps extends FormFieldProps {
    /**
     * The date field
     */
    readonly field: FormDateInput;
    /**
     * Busy days as dates comma delimited, for example "YYYY-MM-DD,YYYY-MM-DD"
     */
    readonly busyDays?: string;
    /**
     * Are we loading availability information
     */
    readonly loading: boolean;
}

// Replace * with a regex that matches any number of characters
function isMatch(str: string, pattern: string): boolean {
    const regexPattern = pattern.split("*").map(escapeRegExp).join(".*");
    const regex = new RegExp(`^${regexPattern}$`);
    return regex.test(str);
}

function escapeRegExp(str: string): string {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}

const loadingMessage = "Getting latest availability...";

export const FormFieldDate: FC<FormDateProps> = (props) => {
    // Only go up to 2 months in the future
    const MAX_DATE = dayjs().add(2, "month");
    const TODAY = dayjs();

    const { field, onFieldChange, errorMessage, busyDays, loading } = props;

    // Cant find a more exact definition for this interface
    // Had to look at the props coming in and make a best guess
    interface CustomDayProps extends PickersDayProps<Dayjs> {
        disabled?: boolean;
        day: Dayjs;
        selected?: boolean;
        disableHighlightToday?: boolean;
        today?: boolean;
        outsideCurrentMonth: boolean;
    }

    /**
     * Displays the date depending on if it is disable or in the past
     */
    const CustomDay = (props: CustomDayProps) => {
        const { day, disabled } = props;

        const isPast = day.isBefore(TODAY, "day");

        const isBeyondMaxDate = day.isAfter(MAX_DATE, "day");

        if (isPast || isBeyondMaxDate) {
            return <PickersDay {...props} className={styles.xapp_date_picker_past} />;
        } else if (disabled) {
            return <PickersDay {...props} />;
        }

        // available
        return <PickersDay {...props} className={styles.xapp_date_picker_available} />;
    };

    const RenderLoading = () => <DayCalendarSkeleton />;

    const [dateTimeValue, setDateTimeValue] = React.useState<Dayjs>();

    const onChange = (newValue: Dayjs) => {
        setDateTimeValue(newValue);
    };

    useEffect(() => {
        onFieldChange(field.name, dateTimeValue);
    }, [dateTimeValue, field.name, onFieldChange]);

    useEffect(() => {
        log(`Busy days: "${busyDays}"`);
    }, [busyDays]);

    const disabledDays = busyDays ? busyDays.split(",") : undefined;

    /**
     * Callback to determine if a date should be disabled used by the calendar
     */
    const shouldDisableDate = useCallback(
        (d: Dayjs): boolean => {
            const dateString = d.format("YYYY-MM-DD");
            if (!disabledDays) {
                // See if field has any defaultBusyDays by checking keys
                const hasDefaultBusyDays = Object.keys(field?.defaultBusyDays || {}).length > 0;

                // look for defaultBusyDays on the form or use the DEFAULT_BUSY_DAYS
                const busyDays = hasDefaultBusyDays
                    ? // the extra '|| {}' is to make the subsequent types happy
                      field.defaultBusyDays || {}
                    : DEFAULT_BUSY_DAYS;

                // we look at the defaults
                if (busyDays.blockWeekends && (d.day() === 0 || d.day() === 6)) {
                    return true;
                }

                if (busyDays.blockCurrentDay && d.isSame(TODAY, "day")) {
                    return true;
                }

                if (busyDays.blockNextBusinessDays) {
                    // business days are Monday to Friday

                    let nextBusinessDay = TODAY;

                    let count = 0;

                    while (count < busyDays.blockNextBusinessDays) {
                        nextBusinessDay = nextBusinessDay.add(1, "day");
                        // only increase the count if it is not a weekend
                        if (nextBusinessDay.day() !== 0 && nextBusinessDay.day() !== 6) {
                            count++;
                        }
                    }

                    if (d.isBefore(nextBusinessDay, "day")) {
                        return true;
                    }
                }

                if (busyDays.currentDayAvailableUntil && d.isSame(TODAY, "day")) {
                    //currentDayAvailableUntil is 24 hour format
                    // We will want to run local time to the client
                    // We assume they are in the same timezone
                    // d is always 00:00 so just get the current time

                    const time = dayjs().format("HH:mm");
                    // if we are after the time, we should disable the current day
                    if (time > busyDays.currentDayAvailableUntil) {
                        return true;
                    }
                }

                if (Array.isArray(busyDays.availableDays) && busyDays.availableDays.length > 0) {
                    // availableDays are comma separated days of the week
                    // where we return if it is available
                    const dayString = d.format("dddd");

                    // to help with case insensitivity just lowercase the array of available days
                    const availableDays = busyDays.availableDays.map((day) => day.toLowerCase());

                    if (!availableDays.includes(dayString.toLowerCase())) {
                        return true;
                    }
                }

                return false;
            }

            try {
                const dayString = d.format("dddd");

                // Check if the spec is in the disabledDates array
                if (
                    disabledDays.some((disabledDay) => {
                        if (isMatch(dateString, disabledDay) || isMatch(dayString, disabledDay)) {
                            return true;
                        }

                        return false;
                    })
                ) {
                    return true;
                }
            } catch (err) {
                err(`shouldDisableDate: ${err}`);
            }

            return false;
        },
        [TODAY, disabledDays, field.defaultBusyDays]
    );

    return (
        <>
            <div className={styles.xapp_date_picker_container}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                        className={styles.xapp_date_picker}
                        views={["day"]}
                        value={dateTimeValue}
                        disablePast={true}
                        shouldDisableDate={shouldDisableDate}
                        showDaysOutsideCurrentMonth={true}
                        fixedWeekNumber={6}
                        onChange={onChange}
                        loading={loading}
                        maxDate={MAX_DATE}
                        renderLoading={() => <RenderLoading />}
                        disableHighlightToday={false}
                        slots={{ day: CustomDay }}
                    />
                </LocalizationProvider>
            </div>

            {loading && (
                <FormHelperText
                    error
                    style={{
                        color: "inherit",
                        marginLeft: "98px",
                        marginTop: "14px",
                        marginBottom: "-14px",
                    }}>
                    {loadingMessage}
                </FormHelperText>
            )}

            {!loading && errorMessage && (
                <FormHelperText
                    error
                    style={{ marginLeft: "14px", marginTop: "14px", marginBottom: "-14px" }}>
                    {errorMessage}
                </FormHelperText>
            )}
        </>
    );
};

export default FormFieldDate;
