import React, { FC, useEffect, useState } from "react";

import { FormWidget, FormWidgetConfig, FormWidgetEnv, WidgetStylesheet } from "@xapp/form-widget";
import { getPartnerDetails } from "./utils/partnerDetails";

export interface ContactPageProps extends Pick<FormWidgetEnv, "partnerId" | "businessName"> {
    /**
     * URL of the business logo, displayed in the header
     */
    businessLogoUrl?: string;
    /**
     * Address of the business, displayed in the header
     *
     */
    businessAddress?: string;
    /**
     * Website of the business, displayed in the footer
     */
    businessWebsite?: string;

    config: FormWidgetConfig;

    theme?: {
        backgroundColor?: string;
        headerBackgroundColor?: string;
        headerBusinessNameColor?: string;
    };
    /**
     * Disables the "Powered by XAPP AI" link in the footer
     */
    disablePoweredBy?: boolean;
}

/**
 * Stand alone contact page that uses the FormWidget
 *
 * You must also include WidgetStylesheet on the same view/page as the ContactPage to successfully style the widget.
 * @param props
 * @returns
 */
export const ContactPage: FC<ContactPageProps> = (props) => {
    const {
        config,
        businessAddress,
        businessName,
        businessWebsite,
        businessLogoUrl,
        disablePoweredBy,
        partnerId,
        theme,
    } = props;

    const { backgroundColor, headerBackgroundColor, headerBusinessNameColor } = theme || {};

    const partnerDetails = getPartnerDetails(partnerId);

    return (
        <div className="App" style={{ backgroundColor }}>
            {businessName || businessLogoUrl ? (
                <div className="Header" style={{ backgroundColor: headerBackgroundColor }}>
                    {businessLogoUrl && (
                        <img className={"BusinessLogo"} src={businessLogoUrl} alt={businessName} />
                    )}
                    {businessAddress && (
                        <p className={"BusinessAddress"} style={{ color: headerBusinessNameColor }}>
                            {businessAddress}
                        </p>
                    )}
                </div>
            ) : null}
            <div className="Content">
                <FormWidget config={config} docked={true} />
            </div>
            <div className="Footer" style={{ ...partnerDetails?.style?.footer }}>
                <div className="BusinessInfo">
                    {businessName && <b>{businessName}</b>}
                    {businessAddress && !partnerDetails && <p>{businessAddress}</p>}
                    {businessWebsite && <a href={businessWebsite}>Visit Our Website</a>}
                </div>
                {!disablePoweredBy && !partnerDetails && (
                    <a className={"XAPP"} target="_blank" href="https://xapp.ai">
                        Powered by XAPP AI
                    </a>
                )}
                {partnerDetails && (
                    <div className={"PartnerInfo"}>
                        <p className={"PoweredBy"}>Powered by</p>
                        <a
                            className={"Partner"}
                            target="_blank"
                            href={partnerDetails.partnerWebsite}>
                            <img
                                className={"PartnerLogo"}
                                src={partnerDetails.partnerLogoUrl}
                                alt={partnerDetails.partnerName}
                            />
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};
